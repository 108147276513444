import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { commonRoutes, HomeARRoutes, SuzukiARRoutes } from "../../config/RouteConfig";
import { urls } from "../../config/constants";
import { image2svg } from "../../utilsfunctions/Svg";
import AOS from "aos";
import "aos/dist/aos.css";
import classNames from "classnames";
import { useForm } from "react-hook-form";

const Header = () => {
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onTouched",
	});

	const onSubmit = async (data) => {
		navigate(`/${SuzukiARRoutes.carmodelRoute}/${data?.search}`, { replace: true });
	};

	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const handleShowNavbar = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

	const handleShowSubNavbar = () => {
		setIsSubMenuOpen(!isSubMenuOpen);
	};

	const [isSubMenuOpen4, setIsSubMenuOpen4] = useState(false);

	const handleShowSubNavbar4 = () => {
		setIsSubMenuOpen4(!isSubMenuOpen4);
	};

	const [isWpOpen, setIsWpOpen] = useState(0);

	const handleWpOpen = (t) => {
		setIsWpOpen(t);
	};
	useEffect(() => {
		image2svg();
		AOS.init();
		AOS.refresh();
	}, []);

	return (
		<header className='header'>
			<div className='header__top'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-md-4'>
							<div className='logo'>
								<Link to={`/${`${HomeARRoutes.home}`}`}>
									<picture>
										<source media='(min-width: 768px)' srcSet={`${urls.frontendUrl}/images/logo.png`} />
										<source media='(max-width: 767px)' srcSet={`${urls.frontendUrl}/images/logo.png`} />
										<img src={`${urls.frontendUrl}/images/logo.png`} width='628' height='68' alt='Moosa Abdul Rahman Hassan & Company' />
									</picture>
								</Link>
							</div>
						</div>
						<div className='col-md-8'>
							<div className='header__top--links d-flex align-content-center justify-content-end'>
								<ul className='header__top--logos list-unstyled mb-0 d-flex align-content-center'>
									<li>
										<a href='https://gmc.moosagroup.com/' target='_blank'>
											<img src={`${urls.frontendUrl}/images/GMC-logo-white.png`} width='75' height='20' alt='GSM' />
										</a>
									</li>
									<li>
										<a href={`/${SuzukiARRoutes.home}`} target='_blank'>
											<img src={`${urls.frontendUrl}/images/suzuki-logo-white.png`} width='26' height='32' alt='Suzuki' />
										</a>
									</li>
									<li>
										<a href='https://www.sixt.global/php/reservation/home?language=en_GB&posl=OM&land=OM&fir=412' target='_blank'>
											<img src={`${urls.frontendUrl}/images/SIX2-logo-white.png`} width='48' height='48' alt='SIX2' />
										</a>
									</li>
								</ul>
								{/* <ul className='header__top--social list-unstyled mb-0 d-flex align-content-center'>
									<li>
										<a href='https://www.linkedin.com/company/group-of-moosa-abdulrahman-hassan-llc/' target='_blank'>
											<img src={`${urls.frontendUrl}/images/linkedin.svg`} width='37' height='37' alt='Linkedin' />
										</a>
									</li>
								</ul> */}
								<ul className='header__top--action list-unstyled mb-0 d-flex align-content-center'>
									<li className='header__call'>
										<a href='tel:80076200'>
											<img src={`${urls.frontendUrl}/images/call.png`} alt='Call' /> <span>CALL: 80076200</span>
										</a>
									</li>
									<li>
										<Link to={`/`}>ENG</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='header__main'>
				<div className='container-fluid'>
					<div className='mobile__menu'>
						<div className='mobile__line'></div>
						<div className='mobile__line'></div>
						<div className='mobile__line'></div>
					</div>
					<nav className='navbar navbar-expand-lg p-0'>
						<ul className='navbar-nav'>
							<li className={classNames("nav-item has-child", { active: [`/${HomeARRoutes.aboutusRoute}`].includes(window.location.pathname) })}>
								<a className='nav-link' href='#'>
									شركتنا
								</a>
								<span className='menu-arrow' onClick={handleShowSubNavbar}>
									<img className='in-svg' src={`${urls.frontendUrl}/images/down-arrow.svg`} alt='Arrow' />
								</span>
								<ul className={`nav-sub-menu list-unstyled  ${isSubMenuOpen && "d-block"}`}>
									<li className='sub-menu-item'>
										<Link to={`/${HomeARRoutes.aboutusRoute}`}>من نحن</Link>
									</li>
									<li className='sub-menu-item'>
										<Link to={`/${HomeARRoutes.historyRoute}`}>تاريخنا</Link>
									</li>
									{/*<li className="sub-menu-item">
                                                    <Link to={`/${HomeRoutes.milestone}`}>Milestones</Link>
                                                </li>

                                                <li className="sub-menu-item">
                                                    <Link to={`/${HomeRoutes.accolades}`}>Accolades</Link>
                                                </li>*/}
								</ul>
							</li>

							<li className='nav-item has-child'>
								<a className='nav-link' href='#'>
									خدمة
								</a>
								<span className='menu-arrow'>
									<img className='in-svg' src={`${urls.frontendUrl}/images/down-arrow.svg`} alt='Arrow' />
								</span>
								<ul className='nav-sub-menu list-unstyled'>
									<li className='sub-menu-item'>
										<Link to={`/${HomeARRoutes.book_your_service}`}>احجز خدمتك</Link>
									</li>
									<li className='sub-menu-item'>
										<Link to={`/${HomeARRoutes.menuprice}`}>تسعير القائمة</Link>
									</li>
									<li className='sub-menu-item'>
										<Link to={`/${HomeARRoutes.body_shop}`}>ورشة إصلاح جسم السيارة</Link>
									</li>
									{/* <li className='sub-menu-item'>
										<Link to={`/${HomeARRoutes.bmeppRoute}`}>BM EPP</Link>
									</li> */}
									<li className='sub-menu-item'>
										<Link to={`/${HomeARRoutes.multifranchiseRoute}`}>خدمات متعددة</Link>
									</li>
								</ul>
							</li>
							<li className='nav-item has-child'>
								<a className='nav-link' href='#'>
									غيار
								</a>
								<span className='menu-arrow'>
									<img className='in-svg' src={`${urls.frontendUrl}/images/down-arrow.svg`} alt='Arrow' />
								</span>
								<ul className='nav-sub-menu list-unstyled'>
									<li className='sub-menu-item'>
										<Link to={`/${HomeARRoutes.partEnquiryRoute}`}>الاستعلام عن قطع غيار</Link>
									</li>
									{/* <li className='sub-menu-item'>
										<a href='#'>ACCESSORIES</a>
									</li> */}
									<li className='sub-menu-item'>
										<Link to={`/${HomeARRoutes.vehicleCareAndMaintenanceRoute}`}>منتجات العناية بالمركبات وصيانتها</Link>
									</li>
								</ul>
							</li>
							{/*<li className="nav-item">
                                            <Link to={`/${HomeARRoutes.ownersRoute}`} className="nav-link">owners</Link>
                                        </li>*/}
							<li className='nav-item'>
								<a className='nav-link' href='#'>
									العرض الحالي
								</a>
								<span className='menu-arrow'>
									<img className='in-svg' src={`${urls.frontendUrl}/images/down-arrow.svg`} alt='Arrow' />
								</span>
								<ul className='nav-sub-menu list-unstyled'>
									<li className='sub-menu-item'>
										<Link to={`/${HomeARRoutes.gmccurrentoffers}`}>العرض الحالي من جي ام سي</Link>
									</li>
									<li className='sub-menu-item'>
										<Link to={`/${HomeARRoutes.suzukicurrentoffers}`}>العرض الحالي من سوزوكي</Link>
									</li>
									{/* <li className='sub-menu-item'>
										<Link to={`/${HomeARRoutes.service_enquiry}`}>Service Offers</Link>
									</li>
									<li className='sub-menu-item'>
										<Link to={`/${HomeARRoutes.bmeppRoute}`}>BM EPP</Link>
									</li> */}
								</ul>
							</li>

							<li className={classNames("nav-item")}>
								<a href={`https://approvedcar.moosagroup.com/`} target="_blank" className='nav-link'>
								السيارات المعتمدة
								</a>
							</li>

							<li className={classNames("nav-item has-child", { active: [`/${HomeARRoutes.contactusRoute}`, `/${HomeARRoutes.ournetworkRoute}`].includes(window.location.pathname) })}>
								<a className='nav-link' href='#'>
									اتصل بنا
								</a>
								<span className='menu-arrow' onClick={handleShowSubNavbar4}>
									<img className='in-svg' src={`${urls.frontendUrl}/images/down-arrow.svg`} alt='Arrow' />
								</span>
								<ul className={`nav-sub-menu list-unstyled  ${isSubMenuOpen4 && "d-block"}`}>
									<li className='sub-menu-item'>
										<Link to={`/${HomeARRoutes.contactusRoute}`} className='nav-link'>
											اتصل بنا
										</Link>
									</li>
									<li className='sub-menu-item'>
										<Link to={`/${HomeARRoutes.ournetworkRoute}`} className='nav-link'>
											شبكتنا
										</Link>
									</li>
								</ul>
							</li>
							{/* <li className='nav-item'>
								<Link to={`/${HomeARRoutes.moosa_connect}`} className='nav-link'>
									Moosa Connect
								</Link>
							</li> */}
							<li className='nav-item header-search'>
								<form onSubmit={handleSubmit(onSubmit)}>
									<div className='form-group mb-0 position-relative'>
										<input type='text' name='search' id='search' {...register("search")} required className='form-control' placeholder='يبحث' />
										<button type='submit' className='btn-search'>
											<img className='in-svg' src={`${urls.frontendUrl}/images/search-icon.svg`} alt='Search' />
										</button>
									</div>
								</form>
							</li>
						</ul>
						<div className='mobile__items p-2 mt-4 bg-white'>
							<ul className='header__top--logos list-unstyled d-flex align-content-center'>
								<li className='d-flex align-content-center justify-content-center'>
									<a href='#'>
										<img src={`${urls.frontendUrl}/images/GMC-logo.png`} width='75' height='20' alt='GSM' />
									</a>
								</li>
								<li className='d-flex align-content-center justify-content-center'>
									<a href='#'>
										<img src={`${urls.frontendUrl}/images/suzuki-logo.png`} width='26' height='32' alt='Suzuki' />
									</a>
								</li>
								<li className='d-flex align-content-center justify-content-center'>
									<a href='#'>
										<img src={`${urls.frontendUrl}/images/SIX2-logo.png`} width='48' height='48' alt='SIX2' />
									</a>
								</li>
							</ul>
							{/* <ul className='header__top--social list-unstyled mb-0 d-flex align-content-center'>
								<li className='d-flex align-content-center justify-content-center'>
									<a href='#'>
										<img src={`${urls.frontendUrl}/images/linkedin.png`} width='37' height='37' alt='Linkedin' />
									</a>
								</li>
							</ul> */}
						</div>
					</nav>
				</div>
			</div>
			<div className='side-menu' style={{ marginTop: "-100px" }}>
				<Link to={`/${HomeARRoutes.ournetworkRoute}`} className='side-menu-item'>
					<span>شبكتنا</span>
					<img className='in-svg' src={`${urls.frontendUrl}/images/location.svg`} alt='Side Menu Icon' />
				</Link>
				<Link to={`/${HomeARRoutes.downloadBrochures}`} className='side-menu-item'>
					<span>تحميل الكتيب</span>
					<img className='in-svg' src={`${urls.frontendUrl}/images/download.svg`} alt='Side Menu Icon' />
				</Link>
				<Link to={`/${HomeARRoutes.test_drive}`} className='side-menu-item'>
					<span>كتاب اختبار القيادة</span>
					<img className='in-svg' src={`${urls.frontendUrl}/images/car.svg`} alt='Side Menu Icon' />
				</Link>
				<Link to={`/${HomeARRoutes.requestQuoteRoute}`} className='side-menu-item'>
					<span>طلب عرض أسعار</span>
					<img className='in-svg' src={`${urls.frontendUrl}/images/quote.svg`} alt='Side Menu Icon' />
				</Link>
			</div>
			<div className='whatsApp__fixed cursor-pointer' onMouseEnter={() => handleWpOpen(1)} onMouseLeave={() => handleWpOpen(0)}>
				{isWpOpen ? (
					<div className='whatsapp__contacts'>
						<a href='https://wa.me/+96894500004' target='_blank' className='d-flex align-items-center'>
							<img className='in-svg' src='https://www.svgrepo.com/show/176768/whatsapp-social-media.svg' alt='Whatsapp' />
							<p className='m-0 ml-2'>GMC</p>
						</a>
						<a href='https://wa.me/+96894760010' target='_blank' className='d-flex align-items-center'>
							<img className='in-svg' src='https://www.svgrepo.com/show/176768/whatsapp-social-media.svg' alt='Whatsapp' />
							<p className='m-0 ml-2'>Suzuki</p>
						</a>
						<a href='https://wa.me/+96897971702' target='_blank' className='d-flex align-items-center'>
							<img className='in-svg' src='https://www.svgrepo.com/show/176768/whatsapp-social-media.svg' alt='Whatsapp' />
							<p className='m-0 ml-2'>Sixt Rent a Car</p>
						</a>
					</div>
				) : (
					<div className='d-flex align-items-center'>
						<img className='in-svg' src='https://www.svgrepo.com/show/176768/whatsapp-social-media.svg' alt='Whatsapp' />
						<p className='m-0 ml-2'>Chat with us</p>
					</div>
				)}
			</div>
		</header>
	);
};

export default React.memo(Header);
